import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserNotificationInterface } from 'src/app/interfaces/user/user-notification.interface';
import { SystemService } from 'src/app/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  private _currentUsernotifications = new BehaviorSubject<
    UserNotificationInterface[]
  >(null);

  constructor(
    private _http: HttpClient,
    private _systemService: SystemService
  ) {}

  fetchUserNotifications() {
    return this._http.get<any>(
      this._systemService.getApiRootURL() + '/user/notifications'
    );
  }

  getUserNotifications() {
    return this._currentUsernotifications;
  }

  setUserNotifications(notifications: UserNotificationInterface[]) {
    this._currentUsernotifications.next(notifications);
  }

  markNotificationRead(notification: UserNotificationInterface) {
    const data = {
      userNotificationUnqiueId: notification.user_notification_uniqid,
    };
    return this._http.post<any>(
      this._systemService.getApiRootURL() +
        `/user/notifications/${notification.user_notification_uniqid}`,
      data
    );
  }
}
