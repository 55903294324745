import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemService } from 'src/app/services/system.service';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _userProfile = new BehaviorSubject<UserProfileInterface>(null);

  constructor(
    private _systemService: SystemService,
    private _http: HttpClient
  ) {}

  fetchUserProfile() {
    return this._http.get(this._systemService.getApiRootURL() + '/profile', {
      withCredentials: true,
    });
  }

  getUserProfile() {
    return this._userProfile;
  }

  setUserProfile(data: UserProfileInterface) {
    this._userProfile.next(data);
  }

  updateProfile(data: UserProfileInterface) {
    return this._http.put(
      this._systemService.getApiRootURL() + '/profile',
      data, {
        withCredentials: true,
      }
    );
  }
}
