// Core Imports
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { take, exhaustMap } from 'rxjs/operators';

// Services
import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemService } from 'src/app/services/system.service';
import { UserModel } from 'src/app/models/user.model';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _systmService: SystemService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this._authService.getCurrentUserData().pipe(
      take(1),
      exhaustMap((user) => {
        // console.log('AuthInterceptor  ==  User  =  ', user);
        if (!user) {
          // console.log("the Last request sending  =  req  = ", req);
          return next.handle(req);
        }
        let user_tokken = user.token;
        if (!user_tokken) {
          const userLocalData: UserModel = JSON.parse(
            localStorage.getItem(this._systmService.getLocalUserAuthKey())
          );
          user_tokken = userLocalData.token;
          // console.log('AuthInterceptor  ==  userLocalData = ', userLocalData);
        }
        // console.log('AuthInterceptor  ==  user_tokken = ', user_tokken);
        const modifiedReq = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + user_tokken)
            // .set(
            //   'x-access-token',
            //   'coinranking70106e197a927494b94b08a0aa6c18f630f617283240e6b9'
            // )
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Headers', '*')
            .set('Access-Control-Allow-Methods', '*')
            .set('Accepts', '*'),
        });
        // console.log("the Last request sending  =  modifiedReq  = ", modifiedReq);
        return next.handle(modifiedReq);
      })
    );
  }
}
