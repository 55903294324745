import { ChartDataSets, ChartOptions } from 'chart.js';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';
import { CURRENCIES } from 'src/app/currencies';
import { ExternalServerCoinInterface } from 'src/app/interfaces/coin/external-server-coin-interface';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
import { CoinInterface } from 'src/app/interfaces/coin/coin';
import { Color } from 'ng2-charts';
import { UserStockInvestmentInterface } from '../interfaces/stock-investment/user-stock-investment-interface';

export const defaultChartDataArray = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0,
]; // need to return 27 values

export const defaultChartLabels = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
];

export const defaultChartOptions: ChartOptions & { annotation: any } = {
  responsive: true,
  annotation: true,
  animation: {
    easing: 'easeInOutBack',
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  plugins: {},
  scales: {
    type: 'linear',
    yAxes: [
      {
        display: false,
      },
    ],
    xAxes: [
      {
        display: false,
      },
    ],
  },
};

export const defaultChartColors: Color[] = [
  {
    borderColor: '#5172cb',
    backgroundColor: 'rgb(72, 66, 163, .8)',
  },
];

export const chartColorsForSingleCoinView: Color[] = [
  {
    borderColor: '#5172cb',
    backgroundColor: 'rgb(122, 185, 244, .15)',
  },
];

export const defaultLineChartData: ChartDataSets = {
  data: [...defaultChartDataArray],
  label: 'values',
  pointRadius: 0,
  borderJoinStyle: 'miter',
  lineTension: 0.08,
  backgroundColor: 'rgba(330,20,110,.09)',
  cubicInterpolationMode: 'monotone',
  fill: true,
};

export const manualSavingHistory = (manualSaving: ManualSavingInterface) => {
  let loopCount = 27;
  if (
    manualSaving !== null &&
    manualSaving.manual_saving_history &&
    manualSaving.manual_saving_history !== null &&
    manualSaving.manual_saving_history.length > 0
  ) {
    loopCount = loopCount - manualSaving.manual_saving_history.length;
  }
  const resultArray = [];
  for (let i = 0; i < loopCount; i++) {
    resultArray.push(0);
  }
  for (let j = 0; j < manualSaving.manual_saving_history.length; j++) {
    resultArray.push(
      parseFloat(manualSaving.manual_saving_history[j].current_amount)
    );
  }
  return [...resultArray];
};

export const activeUserCurrencySambol = (userData: UserProfileInterface) => {
  if (userData !== null) {
    const currency = userData.currency.toUpperCase();
    if (typeof CURRENCIES[currency] !== 'undefined') {
      if (typeof CURRENCIES[currency]['symbol_native'] !== 'undefined') {
        return CURRENCIES[currency]['symbol_native'];
      } else {
        return CURRENCIES['USD']['symbol_native'];
      }
    } else {
      return CURRENCIES['USD']['symbol_native'];
    }
  } else {
    return CURRENCIES['USD']['symbol_native'];
  }
};

export const usernameChanged = (
  newUserName: string,
  userData: UserProfileInterface
) => {
  if (userData != null) {
    if (newUserName != userData?.portfolio_name) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const cryptoCoinsNetPrice = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[]
) => {
  if (userData !== null && coinsDataFromExternalServer !== null) {
    if (
      userData.coins !== null &&
      userData.coins.length > 0 &&
      coinsDataFromExternalServer.length > 0
    ) {
      let sum = 0;
      let externalCoin = null;
      userData.coins.forEach((coin) => {
        externalCoin = null;
        if (coin.external_server_coin_id) {
          externalCoin = coinsDataFromExternalServer.find(
            (el) => el.uuid == coin.external_server_coin_id
          );
        }
        if (externalCoin) {
          sum += parseFloat(externalCoin.price) * parseFloat(coin.amount);
        }
      });
      return sum;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const cryptoCoinsChartData = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[]
) => {
  if (userData && coinsDataFromExternalServer) {
    if (
      userData.coins &&
      userData.coins.length > 0 &&
      coinsDataFromExternalServer.length > 0
    ) {
      const sumCoinsChange = {};
      for (let i = 0; i < 27; i++) {
        sumCoinsChange[i] = 0;
      }
      let sumCoinLoopIndexCharge = 0;
      let currentLoopExternalCoin: ExternalServerCoinInterface = null;
      let userAcountCoin: CoinInterface = null;
      for (let j = 0; j < coinsDataFromExternalServer.length; j++) {
        currentLoopExternalCoin = null;
        currentLoopExternalCoin = coinsDataFromExternalServer[j];
        userAcountCoin = userData.coins.find(
          (el) => el.external_server_coin_id == currentLoopExternalCoin.uuid
        );
        if (userAcountCoin && userAcountCoin.amount > 0) {
          if (
            currentLoopExternalCoin.sparkline &&
            currentLoopExternalCoin.sparkline.length > 0
          ) {
            for (let k = 0; k < currentLoopExternalCoin.sparkline.length; k++) {
              sumCoinLoopIndexCharge = 0;
              sumCoinsChange[k] =
                sumCoinsChange[k] +
                (currentLoopExternalCoin.sparkline[k]
                  ? parseFloat(currentLoopExternalCoin.sparkline[k])
                  : 0);
            }
          }
        }
      }
      const resultSumCoinsChargeArray = Object.keys(sumCoinsChange).map(
        (key) => sumCoinsChange[key]
      );
      return [...resultSumCoinsChargeArray];
    } else {
      return [...defaultChartDataArray];
    }
  } else {
    return [...defaultChartDataArray];
  }
};

export const getCryptoCoinLatestPrice = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  coin: CoinInterface
) => {
  if (userData && coinsDataFromExternalServer) {
    if (
      userData.coins &&
      userData.coins.length > 0 &&
      coinsDataFromExternalServer.length > 0
    ) {
      let externalCoin = null;
      if (coin.external_server_coin_id) {
        externalCoin = coinsDataFromExternalServer.find(
          (el) => el.uuid == coin.external_server_coin_id
        );
      }
      if (externalCoin) {
        let externalCoinPrice = parseFloat(externalCoin.price);
        return externalCoinPrice;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const PortfolioCryptoCoinsPriceChange = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[]
) => {
  if (userData && coinsDataFromExternalServer) {
    if (
      userData.coins &&
      userData.coins.length > 0 &&
      coinsDataFromExternalServer.length > 0
    ) {
      let change = 0;
      coinsDataFromExternalServer.forEach((el) => {
        if (el) {
          if (el.change) {
            change += parseFloat(el.change);
          }
        }
      });
      return change / coinsDataFromExternalServer.length;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const cryptoCoinsNetPriceChange = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  externalCoinId: string
) => {
  if (userData && coinsDataFromExternalServer) {
    if (
      userData.coins &&
      userData.coins.length > 0 &&
      coinsDataFromExternalServer.length > 0
    ) {
      let externalCoin = coinsDataFromExternalServer.find(
        (el) => el.uuid == externalCoinId
      );
      console.log({
        externalCoin,
        externalCoinId,
        coinsDataFromExternalServer,
      });
      return externalCoin ? externalCoin.change : 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateCryptoCoinPortfolioPercentage = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  coin: CoinInterface
) => {
  const cryptoCoinsNetPriceResult = cryptoCoinsNetPrice(
    userData,
    coinsDataFromExternalServer
  );
  if (cryptoCoinsNetPriceResult) {
    return ((coin.amount / cryptoCoinsNetPriceResult) * 100).toFixed(2);
  } else {
    return 0.0;
  }
};

export const manualSavingsNetPrice = (
  userData: UserProfileInterface,
  manualSavings: ManualSavingInterface[]
) => {
  if (userData && manualSavings) {
    if (manualSavings.length > 0) {
      let sum = 0;
      manualSavings.forEach((el) => {
        if (el.amount) {
          sum += parseFloat(el.amount);
        }
      });
      return sum;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateManualSavingPortfolioPercentage = (
  userData: UserProfileInterface,
  manualSavings: ManualSavingInterface[],
  manualSaving: ManualSavingInterface
) => {
  const manualSavingsNetPriceResult = manualSavingsNetPrice(
    userData,
    manualSavings
  );
  if (manualSavingsNetPriceResult) {
    return ((manualSaving.amount / manualSavingsNetPriceResult) * 100).toFixed(
      2
    );
  } else {
    return 0.0;
  }
};

export const portfolioManualSavingPriceChange = (
  userData: UserProfileInterface,
  manualSavings: ManualSavingInterface[]
) => {
  if (userData && manualSavings) {
    if (manualSavings.length > 0) {
      let change = 0;
      manualSavings.forEach((el) => {
        if (el) {
          if (el.change_status && el.change_percentage) {
            if (el.change_status == 'positive') {
              change += parseFloat(el.change_percentage);
            } else {
              change -= parseFloat(el.change_percentage);
            }
          }
        }
      });
      return change / manualSavings.length;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const manualSavingsChartData = (
  userData: UserProfileInterface,
  manualSavings: ManualSavingInterface[]
) => {
  if (userData && manualSavings) {
    if (manualSavings.length > 0) {
      const manualSavingsHistoryArray = [];
      for (let j = 0; j < manualSavings.length; j++) {
        manualSavingsHistoryArray.push(manualSavingHistory(manualSavings[j]));
      }
      const resultSumCoinsChargeArray = [...defaultChartDataArray];
      for (let i = 0; i < resultSumCoinsChargeArray.length; i++) {
        for (let k = 0; k < manualSavingsHistoryArray.length; k++) {
          let chartValue = resultSumCoinsChargeArray[i];
          let manualSavingValue = parseFloat(manualSavingsHistoryArray[k][i]);
          resultSumCoinsChargeArray[i] = chartValue + manualSavingValue;
        }
      }
      return [...resultSumCoinsChargeArray];
    } else {
      return [...defaultChartDataArray];
    }
  } else {
    return [...defaultChartDataArray];
  }
};

export const singleCryptoCoinChartData = (
  externalCoinDetails: ExternalServerCoinInterface
) => {
  if (externalCoinDetails) {
    const resultArray = [];
    let currentLoopValue = 0;
    for (let i = 0; i < externalCoinDetails.sparkline.length; i++) {
      currentLoopValue = 0;
      currentLoopValue = externalCoinDetails.sparkline[i]
        ? parseFloat(externalCoinDetails.sparkline[i])
        : 0;
      resultArray.push(currentLoopValue);
    }
    return [...resultArray];
  } else {
    return [...defaultChartDataArray];
  }
};

export const stockMarketValueSelector: string = '4. close';

export const stockHistoryKey: string = 'Time Series (5min)';

export const stockInvestmentHistory = (
  stockInvestment: UserStockInvestmentInterface
) => {
  let loopCount = 26; // it was 27 reduce it to 26 so i can move last_market_value in it's last index
  if (
    stockInvestment !== null &&
    stockInvestment.stock_investment_history &&
    stockInvestment.stock_investment_history !== null &&
    stockInvestment.stock_investment_history.length > 0
  ) {
    loopCount = loopCount - stockInvestment.stock_investment_history.length;
  }
  const resultArray = [];
  for (let i = 0; i < loopCount; i++) {
    resultArray.push(0);
  }
  for (let j = 0; j < stockInvestment.stock_investment_history.length; j++) {
    resultArray.push(
      parseFloat(
        stockInvestment.stock_investment_history[j].current_share_price
      )
    );
  }
  resultArray.push(parseFloat(stockInvestment.last_market_price));
  return [...resultArray];
};

export const stockInvestmentsChartData = (
  userData: UserProfileInterface,
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData && stockInvestments) {
    if (stockInvestments.length > 0) {
      const stockInvestmentsHistoryArray = [];
      for (let j = 0; j < stockInvestments.length; j++) {
        stockInvestmentsHistoryArray.push(
          stockInvestmentHistory(stockInvestments[j])
        );
      }
      const resultSumCoinsChargeArray = [...defaultChartDataArray];
      for (let i = 0; i < resultSumCoinsChargeArray.length; i++) {
        for (let k = 0; k < stockInvestmentsHistoryArray.length; k++) {
          let chartValue = resultSumCoinsChargeArray[i];
          let StockInvestmentValue = parseFloat(
            stockInvestmentsHistoryArray[k][i]
          );
          resultSumCoinsChargeArray[i] = chartValue + StockInvestmentValue;
        }
      }
      return [...resultSumCoinsChargeArray];
    } else {
      return [...defaultChartDataArray];
    }
  } else {
    return [...defaultChartDataArray];
  }
};

export const stockInvestmentsNetPrice = (
  userData: UserProfileInterface,
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData && stockInvestments) {
    if (stockInvestments.length > 0) {
      let sum = 0;
      stockInvestments.forEach((el) => {
        if (el.share_price && el.number_of_shares) {
          sum += parseFloat(el.share_price) * parseFloat(el.number_of_shares);
        }
      });
      return sum;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const calculateStockInvestmentPortfolioPercentage = (
  userData: UserProfileInterface,
  stockInvestments: UserStockInvestmentInterface[],
  stockInvestment: UserStockInvestmentInterface
) => {
  const stockInvestmentsNetPriceResult = stockInvestmentsNetPrice(
    userData,
    stockInvestments
  );
  if (stockInvestmentsNetPriceResult) {
    return (
      ((parseFloat(stockInvestment.share_price) *
        parseFloat(stockInvestment.number_of_shares)) /
        stockInvestmentsNetPriceResult) *
      100
    ).toFixed(2);
  } else {
    return 0.0;
  }
};

export const portfolioStockInvestmentPriceChange = (
  userData: UserProfileInterface,
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData && stockInvestments) {
    if (stockInvestments.length > 0) {
      let change = 0;
      stockInvestments.forEach((el) => {
        if (el) {
          if (el.change_status && el.change_percentage) {
            if (el.change_status == 'positive') {
              change += parseFloat(el.change_percentage);
            } else {
              change -= parseFloat(el.change_percentage);
            }
          }
        }
      });
      return change / stockInvestments.length;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const mainDashboardChartData = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  manualSavings: ManualSavingInterface[],
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData) {
    const cryptoCoinsChartDataResult = cryptoCoinsChartData(
      userData,
      coinsDataFromExternalServer
    );
    const manualSavingsChartDataResult = manualSavingsChartData(
      userData,
      manualSavings
    );
    const stockInvestmentsChartDataResult = stockInvestmentsChartData(
      userData,
      stockInvestments
    );
    const mainDashboardChartDataResult = [...defaultChartDataArray];
    for (let i = 0; i < mainDashboardChartDataResult.length; i++) {
      mainDashboardChartDataResult[i] =
        cryptoCoinsChartDataResult[i] +
        manualSavingsChartDataResult[i] +
        stockInvestmentsChartDataResult[i];
    }
    return [...mainDashboardChartDataResult];
  } else {
    return [...defaultChartDataArray];
  }
};

export const mainDashboardNetPrice = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  manualSavings: ManualSavingInterface[],
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData) {
    const cryptoCoinsNetPriceResult = cryptoCoinsNetPrice(
      userData,
      coinsDataFromExternalServer
    );
    const manualSavingsNetPriceResult = manualSavingsNetPrice(
      userData,
      manualSavings
    );
    const stockInvestmentsNetPriceResult = stockInvestmentsNetPrice(
      userData,
      stockInvestments
    );
    return (
      cryptoCoinsNetPriceResult +
      manualSavingsNetPriceResult +
      stockInvestmentsNetPriceResult
    );
  } else {
    return 0;
  }
};

export const mainDashboardUserPortfolioPriceChange = (
  userData: UserProfileInterface,
  coinsDataFromExternalServer: ExternalServerCoinInterface[],
  manualSavings: ManualSavingInterface[],
  stockInvestments: UserStockInvestmentInterface[]
) => {
  if (userData) {
    const cryptoCoinsPortfolioPriceChargeResult =
      PortfolioCryptoCoinsPriceChange(userData, coinsDataFromExternalServer);
    const manualSavingsPortfolioPriceChargeResult =
      portfolioManualSavingPriceChange(userData, manualSavings);
    const stockInvestmentsPortfolioPriceChargeResult =
      portfolioStockInvestmentPriceChange(userData, stockInvestments);
    return (
      cryptoCoinsPortfolioPriceChargeResult +
      manualSavingsPortfolioPriceChargeResult +
      stockInvestmentsPortfolioPriceChargeResult
    );
  } else {
    return 0;
  }
};
