import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ManualSavingService } from 'src/app/services/manual-saving/manual-saving.service';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserNotificationInterface } from 'src/app/interfaces/user/user-notification.interface';
import { activeUserCurrencySambol } from 'src/app/utils/helpers';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';

@Component({
  selector: 'app-manual-saving-update-reminder-modal',
  templateUrl: './manual-saving-update-reminder-modal.component.html',
  styleUrls: ['./manual-saving-update-reminder-modal.component.scss'],
})
export class ManualSavingUpdateReminderModalComponent
  implements OnInit
{
  // helper functions
  activeUserCurrencySambol = activeUserCurrencySambol;

  // component Input variables
  @Input() manualSavingUniqueId: string = null;
  @Input() notification: UserNotificationInterface = null;
  @Input() userData: UserProfileInterface = null;

  currentStep: 'info' | 'increase_manual_saving' | 'decrease_manual_saving' =
    'info';
  updatedSavingAmount = 0;
  manualSavingData: ManualSavingInterface = null;

  private _fetchManualSavingData_Sub: Subscription;
  private _updateManualSaving_Sub: Subscription;
  private _remindMeTomorrow_Sub: Subscription;

  constructor(
    public _activeModal: ModalController,
    private _manualSavingService: ManualSavingService,
    private _alertCtl: AlertController,
    private _ngxService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.fetchManualSavingsData();
  }

  fetchManualSavingsData() {
    this._ngxService.startLoader('manualSavingUpdateReminderModalLoader');
    this._fetchManualSavingData_Sub = this._manualSavingService
      .fetchManualSavingDetails(this.manualSavingUniqueId)
      .subscribe(
        (res) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === fetchManualSavingsData == res = ',
            { res }
          );
          this.manualSavingData = res.data;
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
        },
        (err) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === fetchManualSavingsData == err = ',
            { err }
          );
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
        }
      );
  }

  changeCurrentStep(
    step: 'info' | 'increase_manual_saving' | 'decrease_manual_saving'
  ) {
    this.currentStep = step;
    this.updatedSavingAmount = 0;
  }

  remindMeTomorrow() {
    this._ngxService.startLoader('manualSavingUpdateReminderModalLoader');
    this._remindMeTomorrow_Sub = this._manualSavingService
      .remindMeTomorrow(
        this.manualSavingUniqueId,
        this.notification.user_notification_uniqid
      )
      .subscribe(
        (res) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === fetchManualSavingsData == res = ',
            { res }
          );
          this.manualSavingData = res.data;
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
          this.closeModal('reminderSetForTomorrow');
        },
        (err) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === fetchManualSavingsData == err = ',
            { err }
          );
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
          alert(
            'Error Occured while updating manual saving reminder, refresh page and try again'
          );
        }
      );
  }

  closeModal(code: 'reminderSetForTomorrow' | 'manualSavingUpdated' = null) {
    this._activeModal.dismiss({ code });
  }

  async updateManualSaving() {
    // return is new amount if less-than/equal-to 0
    let alert;
    if (this.updatedSavingAmount <= 0) {
      alert = await this._alertCtl.create({
        message: 'Invalid amount, amount should be greater than 0.',
        buttons: [{ text: 'OKAY' }],
      });
      alert.present();
      return;
    }

    // return if in decrease mode and new amount if greater than current manual saving amount
    if (this.currentStep === 'decrease_manual_saving') {
      if (this.updatedSavingAmount > this.manualSavingData.amount) {
        alert = await this._alertCtl.create({
          message:
            "Invalid amount, new amount can't be greater than current amount.",
          buttons: [{ text: 'OKAY' }],
        });
        alert.present();
        return;
      }
    }

    const data: ManualSavingInterface = { amount: this.updatedSavingAmount };
    if (this.currentStep === 'increase_manual_saving') {
      data.amount_update_action = 'increase';
    }
    if (this.currentStep === 'decrease_manual_saving') {
      data.amount_update_action = 'decrease';
    }
    this._ngxService.startLoader('manualSavingUpdateReminderModalLoader');
    const successAlert = await this._alertCtl.create({
      message: 'Manual saving updated successfully.',
      buttons: [{ text: 'OKAY' }],
    });
    const errorAlert = await this._alertCtl.create({
      message:
        'Error Occured while updating manual saving, refresh page and try again.',
      buttons: [{ text: 'OKAY' }],
    });
    this._updateManualSaving_Sub = this._manualSavingService
      .updateManualSavingPrice(
        this.manualSavingUniqueId,
        this.notification.user_notification_uniqid,
        data
      )
      .subscribe(
        (res) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === updateManualSaving == res = ',
            {
              res,
            }
          );
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
          successAlert.present();
          successAlert.onDidDismiss().then(() => {
            this.closeModal('manualSavingUpdated');
          });
        },
        (err) => {
          console.log(
            'ManualSavingUpdateReminderModalComponent === updateManualSaving == err = ',
            {
              err,
            }
          );
          this._ngxService.stopLoader('manualSavingUpdateReminderModalLoader');
          errorAlert.present();
          errorAlert.onDidDismiss().then(() => {
            window.location.reload();
          });
        }
      );
  }

  ionViewWillLeave() {
    if (this._updateManualSaving_Sub) {
      this._updateManualSaving_Sub.unsubscribe();
    }
    if (this._fetchManualSavingData_Sub) {
      this._fetchManualSavingData_Sub.unsubscribe();
    }
    if (this._remindMeTomorrow_Sub) {
      this._remindMeTomorrow_Sub.unsubscribe();
    }
  }
}
