import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title = 'Investment Saving Dashboard - Zaions.com';
  @Input() description = 'Investment Saving Dashboard - Zaions.com';
  @Input() keywords = 'Investment Saving Dashboard - Zaions.com';

  constructor(
    private _title: Title,
    private _meta: Meta,
    private _router: Router,
    private _http: HttpClient,
    private _cookieService: CookieService
  ) {}

  ngOnInit() {
    this._title.setTitle(this.title);
    this._meta.addTags([
      { name: 'description', content: this.description + this.title },
      { name: 'title', content: this.title },
      {
        name: 'keywords',
        content: this.title + this.description + this.keywords,
      },
    ]);
  }

  openAddPortfolio() {
    this._router.navigate(['/add']);
  }

  openDashboard() {
    this._router.navigate(['/dashboard/all']);
  }

  testCsrfToken() {
    this._http
      .get('https://isd-staging.thespecialsomething.com', {observe: 'response'})
      .subscribe((res: any) => {
        const k = res.headers.get('set-cookie');
        const cookies = this._cookieService.getAll();

        console.log({cookies, k});
      });
  }
}
