import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UserNotificationInterface } from 'src/app/interfaces/user/user-notification.interface';
import { UserNotificationService } from 'src/app/services/user/user-notification.service';
import { ManualSavingUpdateReminderModalComponent } from 'src/app/components/trackers/manual-savings/manual-saving-update-reminder-modal/manual-saving-update-reminder-modal.component';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';
import { ManualSavingService } from 'src/app/services/manual-saving/manual-saving.service';

@Component({
  selector: 'app-user-notification-tile',
  templateUrl: './user-notification-tile.component.html',
  styleUrls: ['./user-notification-tile.component.scss'],
})
export class UserNotificationTileComponent implements OnInit {
  @Input() notification: UserNotificationInterface = null;
  @Input() userNotificationsDropdown;
  @Input() userData: UserProfileInterface = null;

  private _markAsRead_Sub: Subscription;
  private _fetchNotification_Sub: Subscription;
  private _fetchManualSavings_Sub: Subscription;

  constructor(
    private _userNotificationService: UserNotificationService,
    private _modalCtl: ModalController,
    private _manualSavingService: ManualSavingService
  ) {}

  ngOnInit() {}

  fetchNotifications() {
    this._fetchNotification_Sub = this._userNotificationService
      .fetchUserNotifications()
      .subscribe(
        (res) => {
          // console.log(
          //   'UserNotificationTileComponent === fetchNotifications = ',
          //   res
          // );
          this._userNotificationService.setUserNotifications(res.data);
        },
        (err) => {
          console.log(
            'UserNotificationTileComponent === fetchNotifications == err = ',
            err
          );
          alert(
            'Error Occured while fetching notifications, refresh page and try again!'
          );
        }
      );
  }

  onClick() {
    if (this.notification) {
      if (this.notification.read_at === null) {
        this.markAsRead();
      }

      // if manual saving update remainder
      this.handleManualSavingReminderNotificationClick();

      this.closeNotificationsDropdown();
    }
  }

  closeNotificationsDropdown() {
    this.userNotificationsDropdown.close();
  }

  markAsRead() {
    if (this.notification) {
      if (this.notification.read_at === null) {
        this._markAsRead_Sub = this._userNotificationService
          .markNotificationRead(this.notification)
          .subscribe(
            (res) => {
              // console.log(
              //   'UserNotificationTileComponent === markAsRead == res => ',
              //   { res }
              // );
              this.fetchNotifications();
            },
            (err) => {
              console.log(
                'UserNotificationTileComponent === markAsRead == err => ',
                { err }
              );
            }
          );
      }
    }
  }

  handleManualSavingReminderNotificationClick() {
    if (this.notification.type === 'manualSavingReminderNotification') {
      if (
        this.notification.extra_data &&
        this.notification.extra_data.manual_saving &&
        this.notification.extra_data.manual_saving_reminder_handled !==
          undefined
      ) {
        if (
          this.notification.extra_data.manual_saving_reminder_handled === false
        ) {
          this.showManualSavingUpdateReminderModal();
        }
      }
    }
  }

  // manual saving update reminder modal
  async showManualSavingUpdateReminderModal() {
    const modal = await this._modalCtl.create({
      component: ManualSavingUpdateReminderModalComponent,
      componentProps: {
        manualSavingUniqueId:
          this.notification.extra_data.manual_saving.manual_saving_uniqid,
        notification: this.notification,
        userData: this.userData,
      },
      backdropDismiss: false,
      keyboardClose: false,
      cssClass: 'msu-reminder-modal bg-grey',
    });
    modal.present();
    const modalDismissResult = await modal.onDidDismiss();
    console.log({ modalDismissResult });
    if (modalDismissResult) {
      if (modalDismissResult.data) {
        if (modalDismissResult.data.code) {
          const resultCode: 'reminderSetForTomorrow' | 'manualSavingUpdated' =
            modalDismissResult.data.code;
          // if modalDismissCode is known one then refetch notifications
          if (
            resultCode == 'reminderSetForTomorrow' ||
            resultCode == 'manualSavingUpdated'
          ) {
            this.fetchNotifications();
          }
          // if modalDismissCode is 'manualSavingUpdated' then refetch manual savings
          if (resultCode == 'manualSavingUpdated') {
            this.fetchManualSavingsData();
          }
        }
      }
    }
  }

  fetchManualSavingsData() {
    if (this.userData) {
      this._fetchManualSavings_Sub = this._manualSavingService
        .fetchManualSavings()
        .subscribe(
          (res: { data: ManualSavingInterface[]; status }) => {
            // console.log(
            //   'ManualSavingsListComponent === fetchmanualSavings == res = ',
            //   { res }
            // );
            this._manualSavingService.setManualSavings(res.data);
          },
          (err) => {
            console.log(
              'ManualSavingsListComponent === fetchmanualSavings == err = ',
              { err }
            );
          }
        );
    }
  }

  ionViewWillLeave() {
    if (this._markAsRead_Sub) {
      this._markAsRead_Sub.unsubscribe();
    }
    if (this._fetchNotification_Sub) {
      this._fetchNotification_Sub.unsubscribe();
    }
    if (this._fetchManualSavings_Sub) {
      this._fetchManualSavings_Sub.unsubscribe();
    }
  }
}
