import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserStockInvestmentInterface } from 'src/app/interfaces/stock-investment/user-stock-investment-interface';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
import { activeUserCurrencySambol } from 'src/app/utils/helpers';

@Component({
  selector: 'app-stock-investments-list',
  templateUrl: './stock-investments-list.component.html',
  styleUrls: ['./stock-investments-list.component.scss'],
})
export class StockInvestmentsListComponent implements OnInit {
  // helper functions
  activeUserCurrencySambol = activeUserCurrencySambol;

  @Input() sectionHeading: string = null;
  @Input() stockInvestments: UserStockInvestmentInterface[] = null;

  @Input() userData: UserProfileInterface = null;

  private fetchUserData_Sub: Subscription;
  private getUserData_Sub: Subscription;
  private fetchManualSavings_Sub: Subscription;
  private getManualSavings_Sub: Subscription;

  constructor() {}

  ngOnInit() {}

  trackManualSavingItems(
    index: number,
    itemObject: UserStockInvestmentInterface
  ) {
    return itemObject.id;
  }

  ionViewWillLeave() {
    if (this.fetchUserData_Sub) {
      this.fetchUserData_Sub.unsubscribe();
    }
    if (this.getUserData_Sub) {
      this.getUserData_Sub.unsubscribe();
    }
    if (this.fetchManualSavings_Sub) {
      this.fetchManualSavings_Sub.unsubscribe();
    }
    if (this.getManualSavings_Sub) {
      this.getManualSavings_Sub.unsubscribe();
    }
  }
}
