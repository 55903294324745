import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatar';
import { ChartsModule } from 'ng2-charts';

import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { DashboardHeaderComponent } from 'src/app/components/dashboard-header/dashboard-header.component';
import { ManualSavingUpdateReminderModalComponent } from 'src/app/components/trackers/manual-savings/manual-saving-update-reminder-modal/manual-saving-update-reminder-modal.component';
import { UserNotificationTileComponent } from 'src/app/components/dashboard-header/user-notification-tile/user-notification-tile.component';
import { ManualSavingsListComponent } from 'src/app/components/trackers/manual-savings/manual-savings-list/manual-savings-list.component';
import { CryptoCoinsListComponent } from 'src/app/components/trackers/crypto/crypto-coins-list/crypto-coins-list.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { StockInvestmentsListComponent } from '../components/trackers/stocks/stock-investments-list/stock-investments-list.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    DashboardHeaderComponent,
    ManualSavingUpdateReminderModalComponent,
    UserNotificationTileComponent,
    ManualSavingsListComponent,
    CryptoCoinsListComponent,
    StockInvestmentsListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgbModule,
    ClipboardModule,
    RouterModule,
    AvatarModule,
    ChartsModule,
    NgxUiLoaderModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NgbModule,
    ClipboardModule,
    RouterModule,
    DashboardHeaderComponent,
    AvatarModule,
    ChartsModule,
    ManualSavingUpdateReminderModalComponent,
    UserNotificationTileComponent,
    ManualSavingsListComponent,
    CryptoCoinsListComponent,
    NgxUiLoaderModule,
    StockInvestmentsListComponent,
  ],
  entryComponents: [ManualSavingUpdateReminderModalComponent],
})
export class SharedModule {}
