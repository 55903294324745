import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _systemService: SystemService,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this._systemService.loadingPageDataFalse();
    this.tryAuthLogin();
  }

  tryAuthLogin() {
    this._systemService.loadingPageDataTrue();
    this._authService.autoLogin(() => {
      this._systemService.loadingPageDataFalse();
    });
  }
}
