import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'add',
    loadChildren: () =>
      import('./pages/portfolio/create-portfolio/create-portfolio.module').then(
        (m) => m.CreatePortfolioPageModule
      ),
  },
  {
    path: 'restore',
    loadChildren: () =>
      import(
        './pages/portfolio/restore-portfolio/restore-portfolio.module'
      ).then((m) => m.RestorePortfolioPageModule),
  },
  {
    path: 'dashboard/all',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: 'backup-phrase',
    loadChildren: () =>
      import('./pages/portfolio/backup-phrase/backup-phrase.module').then(
        (m) => m.BackupPhrasePageModule
      ),
  },
  {
    path: 'crypto/coin/add',
    loadChildren: () =>
      import('./pages/trackers/crypto/coin-add/coin-add.module').then(
        (m) => m.CoinAddPageModule
      ),
  },
  {
    path: 'crypto/coin/add/:externalServerCoinId',
    loadChildren: () =>
      import('./pages/trackers/crypto/coin-details/coin-details.module').then(
        (m) => m.CoinDetailsPageModule
      ),
  },
  {
    path: 'crypto/coin/edit/:localCoinUniqueId/:externalServerCoinId',
    loadChildren: () =>
      import('./pages/trackers/crypto/coin-details/coin-details.module').then(
        (m) => m.CoinDetailsPageModule
      ),
  },
  {
    path: 'crypto/coin/view/:localCoinUniqueId/:externalServerCoinId',
    loadChildren: () =>
      import('./pages/trackers/crypto/coin-view/coin-view.module').then(
        (m) => m.CoinViewPageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'select-tracker',
    loadChildren: () =>
      import(
        './pages/trackers/select-saving-type/select-saving-type.module'
      ).then((m) => m.SelectSavingTypePageModule),
  },
  {
    path: 'manual-saving/add-saving',
    loadChildren: () =>
      import(
        './pages/trackers/manual-savings/add-manual-saving/add-manual-saving.module'
      ).then((m) => m.AddManualSavingPageModule),
  },
  {
    path: 'manual-saving/edit/:editManualSavingUniqueId',
    loadChildren: () =>
      import(
        './pages/trackers/manual-savings/add-manual-saving/add-manual-saving.module'
      ).then((m) => m.AddManualSavingPageModule),
  },
  {
    path: 'dashboard/manual-savings',
    loadChildren: () =>
      import(
        './pages/dashboard/manual-savings-tracker-dashboard/manual-savings-tracker-dashboard.module'
      ).then((m) => m.ManualSavingsTrackerDashboardPageModule),
  },
  {
    path: 'dashboard/crypto',
    loadChildren: () =>
      import(
        './pages/dashboard/crypto-tracker-dashboard/crypto-tracker-dashboard.module'
      ).then((m) => m.CryptoTrackerDashboardPageModule),
  },
  {
    path: 'manual-saving/view/:viewManualSavingUniqueId',
    loadChildren: () =>
      import(
        './pages/trackers/manual-savings/view-manual-saving/view-manual-saving.module'
      ).then((m) => m.ViewManualSavingPageModule),
  },
  {
    path: 'dashboard/stock-investments',
    loadChildren: () =>
      import(
        './pages/dashboard/stocks-tracker-dashboard/stocks-tracker-dashboard.module'
      ).then((m) => m.StocksTrackerDashboardPageModule),
  },
  {
    path: 'stock-investment/select-stock',
    loadChildren: () =>
      import(
        './pages/trackers/stocks/select-stock-investment-symbol/select-stock-investment-symbol.module'
      ).then((m) => m.SelectStockInvestmentSymbolPageModule),
  },
  {
    path: 'stock-investment/add/:addStockInvestmentSymbol',
    loadChildren: () =>
      import(
        './pages/trackers/stocks/add-stock-investment/add-stock-investment.module'
      ).then((m) => m.AddStockInvestmentPageModule),
  },
  {
    path: 'stock-investment/edit/:editStockInvestmentUniqueId',
    loadChildren: () =>
      import(
        './pages/trackers/stocks/add-stock-investment/add-stock-investment.module'
      ).then((m) => m.AddStockInvestmentPageModule),
  },
  {
    path: 'stock-investment/view/:viewStockInvestmentUniqueId',
    loadChildren: () =>
      import(
        './pages/trackers/stocks/view-stock-investment/view-stock-investment.module'
      ).then((m) => m.ViewStockInvestmentPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
