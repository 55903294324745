import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemService } from 'src/app/services/system.service';
import { PortfolioInterface } from 'src/app/interfaces/portfolio-interface';
import { UserService } from 'src/app/services/user/user.service';
import { ExternalServerCoinInterface } from 'src/app/interfaces/coin/external-server-coin-interface';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/models/user.model';
import { CoinInterface } from 'src/app/interfaces/coin/coin';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';
import { UserNotificationInterface } from 'src/app/interfaces/user/user-notification.interface';
import { UserNotificationService } from 'src/app/services/user/user-notification.service';
import { ManualSavingService } from 'src/app/services/manual-saving/manual-saving.service';
import { UserStockInvestmentInterface } from 'src/app/interfaces/stock-investment/user-stock-investment-interface';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
  @Input() isDashboardPage: boolean = false;
  @Input() isCoinViewPage: boolean = false;
  @Input() isManualSavingViewPage: boolean = false;
  @Input() isStockInvestmentViewPage: boolean = false;
  @Input() userData: UserProfileInterface = null;
  @Input() localCoinDetails: CoinInterface = null;
  @Input() externalCoinDetails: ExternalServerCoinInterface = null;
  @Input() manualSavingDetails: ManualSavingInterface = null;
  @Input() stockInvestmentDetails: UserStockInvestmentInterface = null;

  @ViewChild('userNotificationsDropdown', { static: false })
  userNotificationsDropdown;

  coins: CoinInterface[] = [];
  portfolios: PortfolioInterface[] = null;
  newUserProfileData: UserProfileInterface = {
    portfolio_name: '',
    time_period: '',
    currency: '',
  };
  userModelData: UserModel = null; // i will use this when user logout
  currentActivePortfolio: PortfolioInterface = {
    is_active: true,
    portfolio: null,
  };
  userNotifications: UserNotificationInterface[] = null;
  isMobile: boolean = false;

  private _updateUserProfile_Sub: Subscription;
  private _getUserPortfolios_Sub: Subscription;
  private _logout_Sub: Subscription;
  private _userModelData_Sub: Subscription;
  private _fetchNotification_Sub: Subscription;
  private _getNotification_Sub: Subscription;
  private _checkManualSavingsReminders_Sub: Subscription;

  get userDataChanged() {
    if (
      this.newUserProfileData?.portfolio_name !=
        this.userData?.portfolio_name ||
      this.newUserProfileData?.currency != this.userData?.currency ||
      this.newUserProfileData?.time_period != this.userData?.time_period
    ) {
      return true;
    } else {
      return false;
    }
  }

  get unreadNotifications() {
    if (this.userNotifications) {
      let count = 0;
      for (let i = 0; i < this.userNotifications.length; i++) {
        if (this.userNotifications[i].read_at === null) {
          count++;
        }
      }
      return count;
    } else {
      return 0;
    }
  }

  constructor(
    private _router: Router,
    private _systemService: SystemService,
    private _authService: AuthService,
    private _userService: UserService,
    private _userNotificationService: UserNotificationService,
    private _manualSavingService: ManualSavingService,
    private _platform: Platform
  ) {}

  ngOnInit() {
    this.newUserProfileData.portfolio_name = this.userData?.portfolio_name;
    this.newUserProfileData.currency = this.userData?.currency;
    this.newUserProfileData.time_period = this.userData?.time_period;

    this.isMobile = this._platform.is(
      'mobile' || 'tablet' || 'mobileweb' || 'phablet'
    );

    this.getUserPortfoliosLocally();
    this.getUserModelData();
    this.fetchNotifications();
    this.getNotifications();

    // check manual savings reminder_interval
    this.checkManualSavingsReminders();
  }

  getUserPortfoliosLocally() {
    this._getUserPortfolios_Sub = this._authService
      .getUserPortfolios()
      .subscribe(
        (res) => {
          // console.log(
          //   'DashboardHeaderComponent === getUserPortfoliosLocally == res = ',
          //   { res }
          // );
          this.portfolios = res;
        },
        (err) => {
          console.log(
            'DashboardHeaderComponent === getUserPortfoliosLocally == err = ',
            { err }
          );
        }
      );
  }

  getUserModelData() {
    this._userModelData_Sub = this._authService.getCurrentUserData().subscribe(
      (res) => {
        // console.log('DashboardHeaderComponent === getUserModelData == res = ', {
        //   res,
        // });
        if (res) {
          this.userModelData = res;
          this.currentActivePortfolio.portfolio = res;
        }
      },
      (err) => {
        console.log('DashboardHeaderComponent === getUserModelData == err = ', {
          err,
        });
      }
    );
  }

  // Modals And Routers Functions
  openBackupPhrase() {
    this._router.navigate(['/backup-phrase']);
  }

  openAddNewPortfolio() {
    this._router.navigate(['/add']);
  }

  signout() {
    this._systemService.loadingPageDataTrue();
    this._logout_Sub = this._authService.logout().subscribe(
      (res) => {
        // console.log(
        //   'DashboardHeaderComponent === Logout Request API Done, Response = ',
        //   res
        // );
        this._authService.removeUserPortfolioLocally(this.userModelData);
        this._systemService.loadingPageDataFalse();
        window.location.reload();
      },
      (err) => {
        console.log(
          'DashboardHeaderComponent === Error While Logout Request API, Error = ',
          err
        );
        alert('Error Occured while logout, refresh page and try again!');
        this._systemService.loadingPageDataFalse();
      }
    );
  }

  openHomePage() {
    this._router.navigate(['/home']);
  }

  // Action Functions
  updateUserProfile() {
    if (this.userDataChanged) {
      this._systemService.loadingPageDataTrue();
      this._updateUserProfile_Sub = this._userService
        .updateProfile(this.newUserProfileData)
        .subscribe(
          (res: { data: UserProfileInterface }) => {
            // console.log(
            //   'DashboardHeaderComponent === updateUserProfile == res = ',
            //   { res }
            // );
            this._userService.setUserProfile(res.data);
            this._systemService.loadingPageDataFalse();
          },
          (err) => {
            console.log(
              'DashboardHeaderComponent === updateUserProfile == err = ',
              { err }
            );
            alert(
              'Error Occured, while updating user profile, refresh page and try again!'
            );
            this._systemService.loadingPageDataFalse();
          }
        );
    }
  }

  switchUserPortfolio(portfolio: PortfolioInterface) {
    this._systemService.loadingPageDataTrue();
    this._authService.swtichUserPortfolioLocally(portfolio, () => {
      this._systemService.loadingPageDataFalse();
      window.location.reload();
    });
  }

  fetchNotifications() {
    this._systemService.loadingPageDataTrue();
    this._fetchNotification_Sub = this._userNotificationService
      .fetchUserNotifications()
      .subscribe(
        (res) => {
          // console.log(
          //   'DashboardHeaderComponent === fetchNotifications = ',
          //   res
          // );
          this._userNotificationService.setUserNotifications(res.data);
          this._systemService.loadingPageDataFalse();
        },
        (err) => {
          console.log(
            'DashboardHeaderComponent === fetchNotifications == err = ',
            err
          );
          alert(
            'Error Occured while fetching notifications, refresh page and try again!'
          );
          this._systemService.loadingPageDataFalse();
        }
      );
  }

  // leave below code here for reference
  getNotifications() {
    this._fetchNotification_Sub = this._userNotificationService
      .getUserNotifications()
      .subscribe(
        (res) => {
          if (res) {
            // console.log('DashboardHeaderComponent === getNotifications = ', {
            //   res,
            //   userNotifications: this.userNotifications,
            // });
            this.userNotifications = res;
          } else {
            this.fetchNotifications();
          }
        },
        (err) => {
          console.log(
            'DashboardHeaderComponent === getNotifications == err = ',
            err
          );
          alert(
            'Error Occured while geting notifications, refresh page and try again!'
          );
        }
      );
  }

  // check manual savings reminder_interval
  checkManualSavingsReminders() {
    this._checkManualSavingsReminders_Sub = this._manualSavingService
      .checkManualSavingsRemindersOnServer()
      .subscribe(
        (res) => {
          // console.log(
          //   'DashboardHeaderComponent === checkManualSavingsReminders = ',
          //   {
          //     res,
          //   }
          // );
        },
        (err) => {
          console.log(
            'DashboardHeaderComponent === checkManualSavingsReminders == err = ',
            err
          );
        }
      );
  }

  closeUserNotificationsDropdown() {
    this.userNotificationsDropdown.close();
  }

  trackUserNotificationItems(
    index: number,
    itemObject: UserNotificationInterface
  ) {
    return itemObject.id;
  }

  trackUserPortfolioItems(index: number, itemObject: PortfolioInterface) {
    return itemObject.portfolio.id;
  }

  ionViewWillLeave() {
    if (this._updateUserProfile_Sub) {
      this._updateUserProfile_Sub.unsubscribe();
    }
    if (this._getUserPortfolios_Sub) {
      this._getUserPortfolios_Sub.unsubscribe();
    }
    if (this._logout_Sub) {
      this._logout_Sub.unsubscribe();
    }
    if (this._userModelData_Sub) {
      this._userModelData_Sub.unsubscribe();
    }
    if (this._fetchNotification_Sub) {
      this._fetchNotification_Sub.unsubscribe();
    }
    if (this._getNotification_Sub) {
      this._getNotification_Sub.unsubscribe();
    }
    if (this._checkManualSavingsReminders_Sub) {
      this._checkManualSavingsReminders_Sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this._updateUserProfile_Sub) {
      this._updateUserProfile_Sub.unsubscribe();
    }
    if (this._getUserPortfolios_Sub) {
      this._getUserPortfolios_Sub.unsubscribe();
    }
    if (this._logout_Sub) {
      this._logout_Sub.unsubscribe();
    }
    if (this._userModelData_Sub) {
      this._userModelData_Sub.unsubscribe();
    }
    if (this._fetchNotification_Sub) {
      this._fetchNotification_Sub.unsubscribe();
    }
    if (this._getNotification_Sub) {
      this._getNotification_Sub.unsubscribe();
    }
    if (this._checkManualSavingsReminders_Sub) {
      this._checkManualSavingsReminders_Sub.unsubscribe();
    }
  }
}
