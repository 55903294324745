import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemService } from 'src/app/services/system.service';
import { BehaviorSubject } from 'rxjs';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';

@Injectable({
  providedIn: 'root',
})
export class ManualSavingService {
  private _userManualSavings = new BehaviorSubject<ManualSavingInterface[]>(
    null
  );

  constructor(
    private _http: HttpClient,
    private _systemService: SystemService
  ) {}

  fetchManualSavings() {
    return this._http.get<any>(
      this._systemService.getApiRootURL() + `/user/manual-savings`
    );
  }

  getManualSavings() {
    return this._userManualSavings;
  }

  setManualSavings(manualSavings: ManualSavingInterface[]) {
    this._userManualSavings.next(manualSavings);
  }

  fetchManualSavingDetails(manualSavingUniqueId: string) {
    return this._http.get<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}`
    );
  }

  addManualSaving(manualSaving: ManualSavingInterface) {
    return this._http.post<any>(
      this._systemService.getApiRootURL() + `/user/manual-savings`,
      manualSaving
    );
  }

  editManualSaving(
    manualSavingUniqueId: string,
    manualSaving: ManualSavingInterface
  ) {
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}`,
      manualSaving
    );
  }

  deleteManualSaving(manualSavingUniqueId: string) {
    return this._http.delete<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}`
    );
  }

  checkManualSavingsRemindersOnServer() {
    return this._http.get<any>(
      this._systemService.getApiRootURL() +
        '/user/manual-savings/check-reminders'
    );
  }

  markMSUpdateReminderHandled(
    manualSavingUniqueId: string,
    userNotificationUnqiueId: string
  ) {
    const data = {
      manual_saving_id: manualSavingUniqueId,
      reminder_handled: true,
    };
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}/change-reminder-status/${userNotificationUnqiueId}`,
      data
    );
  }

  markMSUpdateReminderUnHandled(
    manualSavingUniqueId: string,
    userNotificationUnqiueId: string
  ) {
    const data = {
      manual_saving_id: manualSavingUniqueId,
      reminder_handled: false,
    };
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}/change-reminder-status/${userNotificationUnqiueId}`,
      data
    );
  }

  remindMeTomorrow(
    manualSavingUniqueId: string,
    userNotificationUnqiueId: string
  ) {
    const data = {
      manual_saving_id: manualSavingUniqueId,
      remind_me_tomorrow: true,
    };
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}/remind-me-tomorrow/${userNotificationUnqiueId}`,
      data
    );
  }

  updateManualSavingPrice(
    manualSavingUniqueId: string,
    userNotificationUnqiueId: string,
    manualSaving: ManualSavingInterface
  ) {
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/user/manual-savings/${manualSavingUniqueId}/update-saving/${userNotificationUnqiueId}`,
      manualSaving
    );
  }
}
