import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PortfolioInterface } from 'src/app/interfaces/portfolio-interface';
import { UserModel } from 'src/app/models/user.model';
import { SystemService } from 'src/app/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user = new BehaviorSubject<UserModel>(null);
  private _userID: number = null;
  private _userTokken = new BehaviorSubject<string>(null);
  private _userRoleString: 'admin' | 'customer';
  private _userUsername: string = null;
  private _userRole = new BehaviorSubject<'admin' | 'customer'>(null);
  private _userPortfolios = new BehaviorSubject<PortfolioInterface[]>(null);

  constructor(
    private _http: HttpClient,
    private _systemService: SystemService
  ) {}

  getCurrentUserData() {
    return this._user;
  }

  setCurrentUserData(data) {
    this._user.next(data);
  }

  getUserRole() {
    return this._userRole;
  }

  getUserRoleString() {
    return this._userRoleString;
  }

  getUserID() {
    return this._userID;
  }

  getUserName() {
    return this._userUsername;
  }

  getUserTokken() {
    return this._userTokken;
  }

  setUserRole(role: 'admin' | 'customer') {
    this._userRole.next(role);
  }

  getUserPortfolios() {
    return this._userPortfolios;
  }

  signIn(data) {
    //check user to sign in
    return this._http
      .post<any>(this._systemService.getApiRootURL() + '/login', data, {
        withCredentials: true,
      })
      .pipe(
        catchError(this.errorHandler),
        tap((res) => {
          this.authManager(res.data);
        })
      );
  }

  signUp(data) {
    return this._http
      .post<any>(this._systemService.getApiRootURL() + '/register', data, {
        withCredentials: true,
      })
      .pipe(
        catchError(this.errorHandler),
        tap((res) => {
          this.authManager(res.data);
        })
      );
  }

  autoLogin(callback) {
    const portfoliosString = localStorage.getItem(
      this._systemService.getLocalUserPortfoliosKey()
    );
    const portfolios: PortfolioInterface[] = JSON.parse(portfoliosString);
    if (localStorage.getItem(this._systemService.getLocalUserAuthKey())) {
      const userDataString: string = localStorage.getItem(
        this._systemService.getLocalUserAuthKey()
      );
      const userData: UserModel = JSON.parse(userDataString);
      const localUser = new UserModel(
        userData.id,
        userData.username,
        userData.name,
        userData.email,
        userData.phone_number,
        userData.role,
        userData.token,
        userData.email_verified_at,
        userData.portfolio_name,
        userData.currency,
        userData.time_period,
        userData.backupSentence
      );
      this._user.next(localUser);
      this._userRole.next(userData.role);
      this._userRoleString = userData.role;
      this._userTokken.next(userData.token);
      this._userID = userData.id;
      this._userUsername = userData.username;
      this.checkLoginStatus(callback);
    } else {
      // no user data found, trying to get user local portfolios

      let firstIndexPortfolio: PortfolioInterface = null;
      if (portfolios && portfolios.length > 0) {
        firstIndexPortfolio = portfolios[0];
      }
      if (!firstIndexPortfolio) {
        return;
      }
      const userData: UserModel = firstIndexPortfolio.portfolio;
      if (!userData) {
        return;
      }
      this._user.next(userData);
      this._userRole.next(userData.role);
      this._userRoleString = userData.role;
      this._userTokken.next(userData.token);
      this._userID = userData.id;
      this._userUsername = userData.username;
      localStorage.setItem(
        this._systemService.getLocalUserAuthKey(),
        JSON.stringify(userData)
      );
    }
    this._userPortfolios.next(portfolios);
  }

  authManager(response) {
    const newUser = new UserModel(
      response.id,
      response.username,
      response.name,
      response.email,
      response.phone_number,
      response.role,
      response.token,
      response.email_verified_at,
      response.portfolio_name,
      response.currency,
      response.time_period,
      response.backupSentence
    );
    this._user.next(newUser);
    this._userRole.next(newUser.role);
    this._userRoleString = newUser.role;
    this._userTokken.next(response.tokken);
    this._userID = response.id;
    this._userUsername = response.username;
    this.setUserAuthDataLocally(newUser);
    this.saveUserPortfolioLocally(newUser);
  }

  checkLoginStatus(callback) {
    this._http
      .get(this._systemService.getApiRootURL() + '/check-login-status', {
        withCredentials: true,
      })
      .subscribe(
        (res) => {
          callback();
        },
        (err) => {
          console.log(
            'authservice === checkLoginStatus ==  err = ' +
              JSON.stringify({ err })
          );
          if (err.status == 401) {
            this.logoutUserFormApp(() => {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            });
          }
        }
      );
  }

  private errorHandler(errorRes: HttpErrorResponse) {
    let errorMessage = 'Error Occured';
    console.log(
      'authservice === errorHandler ==  err = ' + JSON.stringify({ errorRes })
    );
    // alert(
    //   'authservice === errorHandler ==  err = ' + JSON.stringify({ errorRes })
    // );
    if (errorRes.status == 401) {
      this.logoutUserFormApp(() => {
        setTimeout(() => {
          window.location.reload();
        }, 300);
      });
    }
    switch (errorRes.message) {
      case 'invalid Data':
        errorMessage = 'Invalid Data Entered';
        break;
    }
    return throwError(errorRes);
  }

  setUserAuthDataLocally(newUser) {
    const data = JSON.stringify(newUser);
    localStorage.setItem(this._systemService.getLocalUserAuthKey(), data);
  }

  swtichUserPortfolioLocally(
    portfolioObj: PortfolioInterface,
    callback = null
  ) {
    const portfoliosString = localStorage.getItem(
      this._systemService.getLocalUserPortfoliosKey()
    );
    if (portfoliosString) {
      const portfolios: PortfolioInterface[] = JSON.parse(portfoliosString);
      if (!portfolios || portfolios.length <= 0) {
        return;
      }
      const index = portfolios.findIndex(
        (el) => el.portfolio.id == portfolioObj.portfolio.id
      );
      if (index < 0) {
        return;
      }
      for (let i = 0; i < portfolios.length; i++) {
        portfolios[i].is_active = false;
      }
      portfolios[index].is_active = true;
      // Setting updated user portfolios
      localStorage.setItem(
        this._systemService.getLocalUserPortfoliosKey(),
        JSON.stringify(portfolios)
      );
      // setting user auth data
      localStorage.setItem(
        this._systemService.getLocalUserAuthKey(),
        JSON.stringify(portfolioObj.portfolio)
      );
      if (callback) {
        callback();
      }
    }
  }

  saveUserPortfolioLocally(userPortfolioData: UserModel, callback = null) {
    if (
      !localStorage.getItem(this._systemService.getLocalUserPortfoliosKey())
    ) {
      // first time setting portfolios locally
      const portfolios: PortfolioInterface[] = [];
      const newPortfolio: PortfolioInterface = {
        is_active: true,
        portfolio: userPortfolioData,
      };
      portfolios.push(newPortfolio);
      localStorage.setItem(
        this._systemService.getLocalUserPortfoliosKey(),
        JSON.stringify(portfolios)
      );
      this._userPortfolios.next(portfolios);
      if (callback) {
        callback();
      }
    } else {
      const portfolios: PortfolioInterface[] = JSON.parse(
        localStorage.getItem(this._systemService.getLocalUserPortfoliosKey())
      );
      const index = portfolios.findIndex(
        (el) => el.portfolio.id == userPortfolioData.id
      );
      if (index > -1) {
        for (let i = 0; i < portfolios.length; i++) {
          portfolios[i].is_active = false;
        }
        portfolios[index].is_active = true;
        localStorage.setItem(
          this._systemService.getLocalUserPortfoliosKey(),
          JSON.stringify(portfolios)
        );
        this._userPortfolios.next(portfolios);
        if (callback) {
          callback();
        }
      } else {
        for (let i = 0; i < portfolios.length; i++) {
          portfolios[i].is_active = false;
        }
        const newPortfolio = { is_active: true, portfolio: userPortfolioData };
        portfolios.push(newPortfolio);
        localStorage.setItem(
          this._systemService.getLocalUserPortfoliosKey(),
          JSON.stringify(portfolios)
        );
        this._userPortfolios.next(portfolios);
        if (callback) {
          callback();
        }
      }
    }
  }

  removeUserPortfolioLocally(userPortfolioData: UserModel, callback = null) {
    if (
      !localStorage.getItem(this._systemService.getLocalUserPortfoliosKey())
    ) {
      if (callback) {
        callback();
      }
    } else {
      const portfolios: PortfolioInterface[] = JSON.parse(
        localStorage.getItem(this._systemService.getLocalUserPortfoliosKey())
      );
      const updatedPortfolios = portfolios.filter(
        (el) => el.portfolio.id != userPortfolioData.id
      );
      if (updatedPortfolios && updatedPortfolios.length > 0) {
        const firstPortfolio = updatedPortfolios[0];
        updatedPortfolios[0].is_active = true;
        if (firstPortfolio) {
          localStorage.setItem(
            this._systemService.getLocalUserAuthKey(),
            JSON.stringify(firstPortfolio.portfolio)
          );
        }
      } else {
        localStorage.removeItem(this._systemService.getLocalUserAuthKey());
        localStorage.removeItem(
          this._systemService.getLocalUserPortfoliosKey()
        );
      }
      localStorage.setItem(
        this._systemService.getLocalUserPortfoliosKey(),
        JSON.stringify(updatedPortfolios)
      );
      this._userPortfolios.next(updatedPortfolios);
      if (callback) {
        callback();
      }
    }
  }

  logout() {
    const data = 'ok';
    return this._http.post<any>(
      this._systemService.getApiRootURL() + '/logout',
      data
    );
  }

  logoutUserFormApp(callback = null) {
    // alert('ok');
    const userModelData = JSON.parse(
      localStorage.getItem(this._systemService.getLocalUserAuthKey())
    );
    localStorage.removeItem(this._systemService.getLocalUserAuthKey());
    if (callback) {
      this.removeUserPortfolioLocally(userModelData, callback);
    } else {
      this.removeUserPortfolioLocally(userModelData);
    }
  }
}
