import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { CoinRankingResponseInterface } from 'src/app/interfaces/coin-ranking-response-interface';
import { CoinInterface } from 'src/app/interfaces/coin/coin';
import { ExternalServerCoinInterface } from 'src/app/interfaces/coin/external-server-coin-interface';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
// import { CoinService } from 'src/app/services/coin/coin.service';
// import { SystemService } from 'src/app/services/system.service';
// import { UserService } from 'src/app/services/user/user.service';
import {
  activeUserCurrencySambol,
  cryptoCoinsNetPrice,
  getCryptoCoinLatestPrice,
  PortfolioCryptoCoinsPriceChange,
  cryptoCoinsNetPriceChange,
  calculateCryptoCoinPortfolioPercentage,
} from 'src/app/utils/helpers';

@Component({
  selector: 'app-crypto-coins-list',
  templateUrl: './crypto-coins-list.component.html',
  styleUrls: ['./crypto-coins-list.component.scss'],
})
export class CryptoCoinsListComponent implements OnInit {
  // helper functions
  activeUserCurrencySambol = activeUserCurrencySambol;
  cryptoCoinsNetPrice = cryptoCoinsNetPrice;
  getCryptoCoinLatestPrice = getCryptoCoinLatestPrice;
  PortfolioCryptoCoinsPriceChange = PortfolioCryptoCoinsPriceChange;
  cryptoCoinsNetPriceChange = cryptoCoinsNetPriceChange;
  calculateCryptoCoinPortfolioPercentage =
    calculateCryptoCoinPortfolioPercentage;

  @Input() sectionHeading: string = null;

  @Input() userData: UserProfileInterface = null;
  @Input() coinsDataFromExternalServer: ExternalServerCoinInterface[] = null;

  private fetchUserData_Sub: Subscription;
  private getUserData_Sub: Subscription;
  private getUserData_Sub2: Subscription;
  private fetchUserCoinsDataFromExternalServer_Sub: Subscription;
  private getUserCoinsDataFromExternalServer_Sub: Subscription;

  constructor() // private _userService: UserService, // private _systemService: SystemService,
  // private _coinService: CoinService
  {}

  ngOnInit() {
    console.log('CryptoCoinsListComponent => ngOnInit : ', {
      userData: this.userData,
    });
    // this.fetchUserData();
  }

  // fetchUserData() {
  //   this._systemService.loadingPageDataTrue();
  //   this.fetchUserData_Sub = this._userService.fetchUserProfile().subscribe(
  //     (res: any) => {
  //       this.userData = res.data;
  //       this._userService.setUserProfile(res.data);
  //       this.setupCryptoCoinsChartData(res.data);
  //       this._systemService.loadingPageDataFalse();
  //     },
  //     (err) => {
  //       console.log('CryptoCoinsListComponent === fetchUserData == err = ', {
  //         err,
  //       });
  //       this._systemService.loadingPageDataFalse();
  //     }
  //   );
  // }

  // getUserData() {
  //   this.getUserData_Sub = this._userService
  //     .getUserProfile()
  //     .subscribe((res) => {
  //       if (res) {
  //         this.userData = res;
  //       } else {
  //         this.fetchUserData();
  //       }
  //     });
  // }

  // getUserData2() {
  //   this.getUserData_Sub2 = this._userService
  //     .getUserProfile()
  //     .subscribe((res) => {
  //       if (res) {
  //         this.setupCryptoCoinsChartData(res);
  //       }
  //     });
  // }

  // setupCryptoCoinsChartData(userData: UserProfileInterface) {
  //   const coinIds = [];
  //   if (userData) {
  //     if (userData.coins && userData.coins.length > 0) {
  //       userData.coins.forEach((coin) => {
  //         coinIds.push(coin.external_server_coin_id);
  //       });
  //     }
  //   }
  //   if (coinIds && coinIds.length > 0) {
  //     this.fetchUserCoinsDataFromExternalServer(coinIds.join(','));
  //   }
  // }

  // fetchUserCoinsDataFromExternalServer(coinIds) {
  //   if (this.userData) {
  //     this._systemService.loadingPageDataTrue();
  //     this.fetchUserCoinsDataFromExternalServer_Sub = this._coinService
  //       .fetchSpecificCoinsDataFromExternalServer(
  //         coinIds,
  //         this.userData.currency,
  //         this.userData.time_period
  //       )
  //       .subscribe(
  //         (res: { data: CoinRankingResponseInterface; status }) => {
  //           this._coinService.setSpecificCoinsDataFromExternalServer(
  //             res.data.coins
  //           );
  //           this.coinsDataFromExternalServer = res.data.coins;
  //           this._systemService.loadingPageDataFalse();
  //         },
  //         (err) => {
  //           console.log(
  //             'CryptoCoinsListComponent === fetchUserCoinsDataFromExternalServer == err = ',
  //             { err }
  //           );
  //           this._systemService.loadingPageDataFalse();
  //         }
  //       );
  //   }
  // }

  // getUserCoinsDataFromExternalServer(coinIds) {
  //   this.getUserCoinsDataFromExternalServer_Sub = this._coinService
  //     .getSpecificCoinsDataFromExternalServer()
  //     .subscribe(
  //       (res) => {
  //         if (res) {
  //           this.coinsDataFromExternalServer = res;
  //         } else {
  //           this.fetchUserCoinsDataFromExternalServer(coinIds);
  //         }
  //       },
  //       (err) => {
  //         console.log(
  //           'CryptoCoinsListComponent === getUserCoinsDataFromExternalServer == err = ',
  //           { err }
  //         );
  //       }
  //     );
  // }

  trackUserCoinItems(index: number, itemObject: CoinInterface) {
    return itemObject.id;
  }

  ionViewWillLeave() {
    if (this.fetchUserData_Sub) {
      this.fetchUserData_Sub.unsubscribe();
    }
    if (this.getUserData_Sub) {
      this.getUserData_Sub.unsubscribe();
    }
    if (this.getUserData_Sub2) {
      this.getUserData_Sub2.unsubscribe();
    }
    if (this.fetchUserCoinsDataFromExternalServer_Sub) {
      this.fetchUserCoinsDataFromExternalServer_Sub.unsubscribe();
    }
    if (this.getUserCoinsDataFromExternalServer_Sub) {
      this.getUserCoinsDataFromExternalServer_Sub.unsubscribe();
    }
  }
}
