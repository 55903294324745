export class UserModel {
  constructor(
    public id,
    public username,
    public name,
    public email,
    public phone_number,
    public role,
    public token,
    public email_verified_at,
    public portfolio_name,
    public currency,
    public time_period,
    public backupSentence
  ) {}
}
