import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManualSavingInterface } from 'src/app/interfaces/manual-savings/manual-saving.interface';
import { UserProfileInterface } from 'src/app/interfaces/user/user-profile.interface';
// import { ManualSavingService } from 'src/app/services/manual-saving/manual-saving.service';
import { UserService } from 'src/app/services/user/user.service';
import { activeUserCurrencySambol } from 'src/app/utils/helpers';

@Component({
  selector: 'app-manual-savings-list',
  templateUrl: './manual-savings-list.component.html',
  styleUrls: ['./manual-savings-list.component.scss'],
})
export class ManualSavingsListComponent implements OnInit {
  // helper functions
  activeUserCurrencySambol = activeUserCurrencySambol;

  @Input() sectionHeading: string = null;
  @Input() manualSavings: ManualSavingInterface[] = null;

  userData: UserProfileInterface = null;

  private fetchUserData_Sub: Subscription;
  private getUserData_Sub: Subscription;
  private fetchManualSavings_Sub: Subscription;
  private getManualSavings_Sub: Subscription;

  constructor(
    private _userService: UserService,
    // private _manualSavingService: ManualSavingService
  ) {}

  ngOnInit() {
    this.getUserData();
    // this.fetchManualSavingsData();
  }

  fetchUserData() {
    this.fetchUserData_Sub = this._userService.fetchUserProfile().subscribe(
      (res: any) => {
        // console.log('ManualSavingsListComponent === fetchUserData == res = ', { res });
        this._userService.setUserProfile(res.data);
      },
      (err) => {
        console.log('ManualSavingsListComponent === fetchUserData == err = ', {
          err,
        });
      }
    );
  }

  getUserData() {
    this.getUserData_Sub = this._userService
      .getUserProfile()
      .subscribe((res) => {
        // console.log('ManualSavingsListComponent === getUserData == res = ', { res });
        if (res) {
          this.userData = res;
        } else {
          this.fetchUserData();
        }
      });
  }

  // fetchManualSavingsData() {
  //   this.fetchManualSavings_Sub = this._manualSavingService
  //     .fetchManualSavings()
  //     .subscribe(
  //       (res: { data: ManualSavingInterface[]; status }) => {
  //         console.log(
  //           'ManualSavingsListComponent === fetchmanualSavings == res = ',
  //           { res }
  //         );
  //         this.manualSavings = res.data;
  //         this._manualSavingService.setManualSavings(res.data);
  //       },
  //       (err) => {
  //         console.log(
  //           'ManualSavingsListComponent === fetchmanualSavings == err = ',
  //           { err }
  //         );
  //       }
  //     );
  // }

  // getManualSavingsData() {
  //   this.getManualSavings_Sub = this._manualSavingService
  //     .getManualSavings()
  //     .subscribe(
  //       (res) => {
  //         // console.log(
  //         //   'ManualSavingsListComponent === fetchmanualSavings == res = ',
  //         //   { res }
  //         // );
  //         if (res) {
  //           this.manualSavings = res;
  //         } else {
  //           this.fetchManualSavingsData();
  //         }
  //       },
  //       (err) => {
  //         console.log(
  //           'ManualSavingsListComponent === fetchmanualSavings == err = ',
  //           { err }
  //         );
  //       }
  //     );
  // }

  trackManualSavingItems(index: number, itemObject: ManualSavingInterface) {
    return itemObject.id;
  }

  ionViewWillLeave() {
    if (this.fetchUserData_Sub) {
      this.fetchUserData_Sub.unsubscribe();
    }
    if (this.getUserData_Sub) {
      this.getUserData_Sub.unsubscribe();
    }
    if (this.fetchManualSavings_Sub) {
      this.fetchManualSavings_Sub.unsubscribe();
    }
    if (this.getManualSavings_Sub) {
      this.getManualSavings_Sub.unsubscribe();
    }
  }
}
