import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private _userAuthKey: string = 'X-AUTH-USER';
  private _userPortfoliosKey: string = 'X-USER-PORTFOLIO';

  // private _coinRankingRootURL: string = 'https://hub.coinranking.com/v1/public';

  // private _apiRootURL: string = 'http://localhost:8000/api'; // local
  // private _apiRootURL: string = 'https://isd.zaions.com/backend/public/api'; // server2
  private _apiRootURL: string = 'https://isdbackend.zaions.com/api'; // server3

  constructor(private _ngxLoaderService: NgxUiLoaderService) {}

  getApiRootURL() {
    return this._apiRootURL;
  }

  getCoinRankingRootURL() {
    return this._apiRootURL + `/coin-ranking`;
  }

  getStockAPIRootURL() {
    return this._apiRootURL + `/stocks-api`;
  }

  getLocalUserAuthKey() {
    return this._userAuthKey;
  }

  getLocalUserPortfoliosKey() {
    return this._userPortfoliosKey;
  }

  loadingPageDataTrue() {
    this._ngxLoaderService.start();
  }

  loadingPageDataFalse() {
    this._ngxLoaderService.stop();
  }
}
